import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {JsonDialogComponent} from '../../../common/components/dialogs/json-modal/json-dialog.component';
import { AdvancedValveRaport } from '../../providers/resources/dto/advanced-valve-raport';
import { BurningTestResult, BurningTestResultResponse } from '../../providers/resources/dto/burning-mode';
import { ThingService } from '../../providers/services/thing.service';
import { tableSettings } from '../../_internal/table-settings';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'app-valve-raport-data',
  templateUrl: './valve-raport-data.component.html',
  styleUrls: ['./valve-raport-data.component.scss']
})
export class ValveRaportDataComponent implements AfterViewInit {

    @Input() thingId: string;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    public tableSettings = _.cloneDeep(tableSettings);
    public columns = [];
    public rows: AdvancedValveRaport[];
    public from = moment().unix() * 1000;
    public to = moment().subtract(7, 'd').unix() * 1000;
    constructor(private thingService: ThingService,
                 private modalService: BsModalService) { }

    ngAfterViewInit(): void {
        this.columns = [
            {prop: 'action_duration_ms', name: 'Action duration', flexGrow: 1, sortable: false},
            {prop: 'action_source', name: 'Action source', flexGrow: 1, sortable: false},
            {prop: 'advanced_report_state', name: 'Advanced valve state', flexGrow: 1, sortable: false},
            {prop: 'agr_date', name: 'Received at', flexGrow: 1, sortable: false},
            {prop: 'current_valve_state', name: 'Valve state', flexGrow: 1, sortable: false},
            {prop: 'current_valve_state_timestamp', name: 'Valve state date', flexGrow: 1, sortable: false},
            {prop: 'motor_state', name: 'Motor state', flexGrow: 1, sortable: false},
            {prop: 'optical_sensor', name: 'Optical state', flexGrow: 1, sortable: false},
            {prop: 'power_supply_connected', name: 'Power supply', flexGrow: 1, sortable: false},
            {prop: 'timestamp', name: 'Device action time', flexGrow: 1, sortable: false},
        ];
    }

    public fetchPage(params?: OptionalHttpParams): void {
        this.thingService.getAdvancedValveRaportData(this.thingId, +this.to, +this.from).subscribe(
            (response: AdvancedValveRaport[]) => {
                this.table.setPageParams({
                    page_number: 1,
                    page_size: response.length,
                    total_entries: response.length,
                    total_pages: 1
                });
                const sortedData = response.sort((a, b) => b.timestamp - a.timestamp);
                this.rows = sortedData.map((report) => {
                    report.timestamp = new Date(report.timestamp).toISOString();
                    report.agr_date = new Date(report.agr_date).toISOString();
                    return report;
                });
                this.table.isLoading = false;
            }
        );
      }

      public reportPassed(result: string): boolean {
        return result === 'passed';
      }

      public openFullReportModal(report: BurningTestResult) {
        this.modalService.show(JsonDialogComponent, {
            initialState: {
                content: report,
                title: `Generated at: ${report.created_at} Serial no: ${report.serial_no}`,
            },
            class: 'modal-xl'
        });
    }
}
