import {NgModule} from '@angular/core';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {JsonDialogComponent} from './json-modal/json-dialog.component';
import {CommonModule, JsonPipe} from '@angular/common';
import {AlertComponent} from './alert/alert.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { CsvDialogComponent } from './csv-dialog/csv-dialog.component';
import { DeviceImportComponent } from './import-device/device-import.component';
import {ExportDeviceComponent} from './export-device/export-device.component';
import {PressureTestGraphComponent} from './pressure-test-graph/pressure-test-graph.component';
import {ManufacturingStatesChangeComponent} from './manufacturing-states-change/manufacturing-states-change.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManufacturingBlockedStatusChangeComponent} from './manufacturing-blocked-status-change/manufacturing-blocked-status-change.component';
import { StatsPageColumnsComponent } from './stats-page-columns/stats-page-columns.component';
import {
    MatSlideToggleModule,
    _MatSlideToggleRequiredValidatorModule,
  } from '@angular/material/slide-toggle';
  
const components = [
    ConfirmationDialogComponent,
    JsonDialogComponent,
    AlertComponent,
    CsvDialogComponent,
    DeviceImportComponent,
    ExportDeviceComponent,
    PressureTestGraphComponent,
    ManufacturingStatesChangeComponent,
    ManufacturingBlockedStatusChangeComponent,
    StatsPageColumnsComponent
];

@NgModule({
    imports: [
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        NgxEchartsModule,
        MatSlideToggleModule
    ],
    providers: [
        JsonPipe
    ],
    exports: [...components, ModalModule],
    declarations: components
})
export class CommonDialogsModule {
}
