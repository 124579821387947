import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CrudResource} from '../../../common/api/CrudResource';
import {Observable, of} from 'rxjs';
import {CanaryData, Firmware, FirmwareUpgrade, FirmwareUpgradeIndex, ReleaseStage} from './dto/firmware.response';
import {OptionalHttpParams} from '../../../common/api/CrudAware';

@Injectable()
export class FirmwareResource extends CrudResource<Firmware> {
    private readonly releaseStagesUrl: string = `${environment.apiGatewayUrl}/firmware/release-stages`;
    private readonly devicesUrl: string = `${environment.apiGatewayUrl}/firmware/devices`;

    constructor(httpClient: HttpClient) {
        super(`${environment.apiGatewayUrl}/firmware/firmwares`, httpClient);
    }

    public download(firmwareId: string): Observable<Blob> {
        const headers = new HttpHeaders().set('Accept', 'application/octet-stream');
        return this.httpClient.get(`${this.URL}/${firmwareId}`, {responseType: 'blob', headers});
    }

    public getReleaseStages(firmwareId: string, channelId: string): Observable<ReleaseStage[]> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.release-stage-index.v1+json');
        const params = {firmware_channel_id: channelId};
        return this.httpClient.get<ReleaseStage[]>(`${this.URL}/${firmwareId}/release-stages`, {params, headers});
    }

    public getReleaseStageDevices(releaseStageId: string, params: {limit: number; offset: number}): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.release-stage-device-index.v1+json');
        return this.httpClient.get(`${this.releaseStagesUrl}/${releaseStageId}/devices`, {params, headers});
    }

    public approveReleaseStage(releaseStageId: string): Observable<ReleaseStage> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.release-stage-approve.v1+json')
                        .set('Accept', 'application/vnd.wtg.release-stage.v1+json');
        const body = {approved: true};
        return this.httpClient.post<ReleaseStage>(`${this.releaseStagesUrl}/${releaseStageId}`, body, {headers});
    }

    public triggerUpgrade(deviceId: string, firmwareId: string): Observable<FirmwareUpgrade> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.upgrade-create.v1+json')
                        .set('Accept', 'application/vnd.wtg.upgrade.v1+json');
        const payload = {firmware_id: firmwareId};
        return this.httpClient.post<FirmwareUpgrade>(`${this.devicesUrl}/${deviceId}/upgrades`, payload, {headers});
    }

    public getFirmwareUpgrades(deviceId: string, params: HttpParams | OptionalHttpParams): Observable<FirmwareUpgradeIndex> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.upgrade-index.v1+json');
        return this.httpClient.get<FirmwareUpgradeIndex>(`${this.devicesUrl}/${deviceId}/upgrades`, {params, headers});
    }

    public cancelFirmwareUpgrade(jobId: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.apiGatewayUrl}/firmware/upgrades?job_id=${jobId}`);
    }

    public getCanaryData(firmwareId: string): Observable<CanaryData> {
        // return this.httpClient.get<CanaryData>(`${this.URL}/${}`);
        const randomData = {
            groups: '-',
            date: '-',
            devices_count: '-',
            online: '-',
            telemetry: '-',
            offline: 0,
            failed: '-',
            pending: '-',
            notUpdated: '-',
        };
        return of(randomData);
    }
}
