<div class="row">
    <div class="col-lg-6 col-xl-7 col-xxl-8">
        <div class="card mb-4" *ngIf="thing">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> {{ thing.name }}
                <div class="float-end buttons-row">
                    <app-favourites-button
                        location="things"
                        [rowID]="thing.id"
                        [rowName]="thing.name"
                    >
                    </app-favourites-button>
                    <span class="btn-toolbar text-center well float-end">
                        <button
                            *ngIf="this.userService.isFirmwareMaster() || this.userService.isFirmwareManager() || this.userService.isRoot()"
                            class="btn btn-secondary btn-sm"
                            (click)="openUpdateDialog()"
                        >
                            Updates
                            <i class="fa fa-download"></i>
                        </button>
                        <button
                            class="btn btn-secondary btn-sm"
                            (click)="openEditModal()"
                        >
                            Edit
                            <i class="fa icons icon-pencil"></i>
                        </button>
                        <button class="btn btn-danger btn-sm"
                                (click)="deleteHub()">
                            Delete
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">
                    <dt>Thing ID:</dt>
                    <dd>
                        <span class="text text-small">{{ thing.id }}</span>
                    </dd>

                    <dt>Hub ID:</dt>
                    <dd>
                        {{ thing.hub_id }}
                    </dd>

                    <dt>Amazon Resource Name:</dt>
                    <dd *ngIf="hub">
                        {{ hub.arn }}
                    </dd>

                    <dt>Internal name:</dt>
                    <dd>
                        {{ thing.internal_name }}
                    </dd>

                    <dt>Type:</dt>
                    <dd>
                        {{ thing.type }}
                    </dd>

                    <dt>Serial No:</dt>
                    <dd>
                        {{ thing.serial_no }}
                    </dd>

                    <dt>State:</dt>
                    <dd>
                        <ng-container *ngIf="loadingState;else notLoading">loading...</ng-container>
                        <ng-template #notLoading>{{ returnValveState(thing) }}</ng-template>
                    </dd>

                    <dt>Version:</dt>
                    <dd>
                        <ng-container *ngIf="hub">
                            {{ hub.version }}
                        </ng-container>
                    </dd>
                    <dt>Firmware channel:</dt>
                    <dd>
                        <ng-container *ngIf="hub">
                            {{ hub.firmware_channel }}
                        </ng-container>
                    </dd>
                    <dt>Upgrade in progress</dt>
                    <dd>
                        <ng-container *ngIf="hub">
                            Hub: {{ hub.upgrade_in_progress }}<br>
                        </ng-container>
                        <ng-container *ngIf="thing">
                            Thing: {{ thing.upgrade_in_progress }}
                        </ng-container>
                    </dd>
                    <dt>Cloud connection:</dt>
                    <dd>
                        <div [ngSwitch]="thing.cloud_connection">
                            <c-badge color="success" *ngSwitchCase="true"
                            >Connected
                            </c-badge>
                            <c-badge color="danger" *ngSwitchCase="false"
                            >Disconnected
                            </c-badge>
                        </div>
                    </dd>

                    <app-battery-feed [batteryFeed]="thing.battery_feed"
                                      [batteryLevel]="thing.battery_level"></app-battery-feed>

                    <dt>Registered at:</dt>
                    <dd>
                        <app-multi-time
                            [date]="thing.created_at"
                            [propertyId]="thing.property_id"
                            [showLocal]="false"
                            [showProperty]="false"
                        ></app-multi-time>
                    </dd>

                    <dt>
                        Wifi rssi:
                        <i
                            class="fa fa-question-circle"
                            [tooltip]="wifiRssiTooltip"
                            placement="bottom"
                        ></i>
                    </dt>
                    <dd>{{ thing.wifi_rssi }}</dd>

                    <ng-template #wifiRssiTooltip>
                        <div class="text-left">
                            > -60 good<br>
                            -60 to -70 fair<br>
                            -70 to -75 poor<br>
                            < -75 weak<br>
                            < -90 no-signal
                        </div>
                    </ng-template>
                </dl>
                <app-thing-actions class="thing-section" [thing]="thing" [isSonicWifi]="true"></app-thing-actions>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="card mb-4"
             *ngIf="thing?.cached_telemetry?.water_temp">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Cached Telemetry
            </div>
            <div class="card-body">
                <app-thing-cached-telemetry
                    [telemetry]="thing.cached_telemetry"
                    [propertyId]="thing.property_id"
                ></app-thing-cached-telemetry>
            </div>
        </div>
        <div class="card mb-4"
             [hidden]="telemetryStatus"
             *ngIf="thing">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Telemetry
            </div>
            <div class="card-body">
                <app-thing-telemetry (telemetryStatus)="toggleTelemetryCard($event)"
                                     [thingId]="thingId"
                                     [propertyId]="thing.property_id"></app-thing-telemetry>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12"></div>
</div>

<div class="mb-4" *ngIf="thing">
    <tabset>
        <tab id="events" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Events</ng-template>
            <div class="row" *ngIf="activeTab === 'events'">
                <div class="col-12">
                    <app-thing-event-list
                        [thing]="thing"
                        [applianceStream]="applianceStream"
                    ></app-thing-event-list>
                </div>
            </div>
        </tab>
        <tab id="legionella" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Legionella checks</ng-template>
            <div class="row" *ngIf="activeTab === 'legionella'">
                <div class="col-12">
                    <app-legionella-checks-list
                        [thing]="thingId"
                    ></app-legionella-checks-list>
                </div>
            </div>
        </tab>
        <tab id="incidents" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Incidents</ng-template>
            <div class="row" *ngIf="activeTab === 'incidents'">
                <div class="col-12">
                    <app-incident-list
                        [propertyId]="thing.property_id"
                    ></app-incident-list>
                </div>
            </div>
        </tab>
        <tab id="firmwares" (selectTab)="tabSelect($event)">
            <ng-template tabHeading>
                Firmwares
            </ng-template>
            <div class="row"
                 *ngIf="activeTab === 'firmwares'">
                <div class="col-12">
                    <app-available-firmware [hub]="hub"
                                            [listType]="'Requested Firmwares'"></app-available-firmware>
                </div>
            </div>
        </tab>
        <tab id="pressure" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Pressure tests</ng-template>
            <div class="row" *ngIf="activeTab === 'pressure'">
                <div class="col-12">
                    <app-pressure-test-list
                        [thing]="thing"
                    >
                    </app-pressure-test-list>
                </div>
            </div>
        </tab>
        <tab id="appliances" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Appliances</ng-template>
            <div class="row" *ngIf="activeTab === 'appliances' && thing">
                <div class="col-12">
                    <app-appliance-list
                        [spaceId]="thing.space_id"
                        [propertyId]="thing.property_id"
                    >
                    </app-appliance-list>
                </div>
            </div>
        </tab>
        <tab id="logs" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Logs</ng-template>
            <div class="row" *ngIf="activeTab === 'logs'">
                <div class="col-lg-12">
                    <app-logs-list [resourceId]="thingId"></app-logs-list>
                </div>
            </div>
        </tab>
        <tab id="lifecycle" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Lifecycle events</ng-template>
            <div class="row" *ngIf="activeTab === 'lifecycle'">
                <div class="col-12">
                    <app-connectivity-timeline
                        title="Thing"
                        [hubId]="thing.hub_id"
                        [thingId]="thingId"
                        [bridgeConnection]="thing.cloud_connection"
                        [sonicWifi]="true"
                    ></app-connectivity-timeline>
                </div>
            </div>
        </tab>
        <tab id="battery" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Battery events</ng-template>
            <div class="row" *ngIf="activeTab === 'battery'">
                <div class="col-12">
                    <app-battery-timeline
                        [thingId]="thingId"
                    ></app-battery-timeline>
                </div>
            </div>
        </tab>
        <tab id="resets" (selectTab)="tabSelect($event)">
            <ng-template tabHeading>
                Remote resets
            </ng-template>
            <div class="row" *ngIf="activeTab === 'resets'">
                <div class="col-12">
                    <app-trigger-resets-list [hubId]="hubId"></app-trigger-resets-list>
                </div>
            </div>
        </tab>
        <tab id="shadowlogs" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Shadow logs sonic</ng-template>
            <div class="row" *ngIf="activeTab === 'shadowlogs'">
                <div class="col-12">
                    <app-shadow-logs [thingId]="thingId" [sonicWifi]="true" [valveStatus]="true"></app-shadow-logs>
                </div>
            </div>
        </tab>
        <tab id="shadow_logs" (selectTab)="tabSelect($event)">
            <ng-template tabHeading>
                Shadow logs hub
            </ng-template>
            <div class="row" *ngIf="activeTab === 'shadow_logs'">
                <div class="col-12">
                    <app-shadow-logs [hubId]="hubId"></app-shadow-logs>
                </div>
            </div>
        </tab>
        <tab id="swaps" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Swaps</ng-template>
            <div class="row" *ngIf="activeTab === 'swaps'">
                <div class="col-12">
                    <app-swap-data [thingId]="thingId"></app-swap-data>
                </div>
            </div>
        </tab>
        <tab id="hourly-data" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Hourly data</ng-template>
            <div class="row" *ngIf="activeTab === 'hourly-data'">
                <div class="col-12">
                    <app-hourly-data [thingId]="thingId"></app-hourly-data>
                </div>
            </div>
        </tab>
        <tab id="burning-mode" (selectTab)="tabSelect($event)">
            <ng-template tabHeading>Burn-in Mode</ng-template>
            <div class="row" *ngIf="activeTab === 'burning-mode'">
                <div class="col-12">
                    <app-burning-mode-data [serialNumber]="thing.serial_no"></app-burning-mode-data>
                </div>
            </div>
        </tab>
        <tab id="valve-raports" (selectTab)="tabSelect($event)">
            <ng-template tabHeading> Valve raports</ng-template>
            <div class="row" *ngIf="activeTab === 'valve-raports'">
                <div class="col-12">
                    <app-valve-raport-data [thingId]="thing.id"></app-valve-raport-data>
                </div>
            </div>
        </tab>
    </tabset>
</div>
