import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { tableSettings } from '../../../../_internal/table-settings';
import { TableComponent } from '../../../../components/table/table.component';
import {OptionalHttpParams} from '../../../../../common/api/CrudAware';
import { PropertiesResponse } from '../../../../providers/resources/dto/property.response';
import {MembershipService} from '../../../../providers/services/membership.service';
import { Property } from '../../../../_internal/property';
import { PropertyService } from '../../../../providers/services/property.service';

@Component({
  selector: 'app-multi-invite',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss']
})
export class InvitePropertiesListComponent implements AfterViewInit {

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('addressColumn') addressColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    public filterFields = [    {
        type: 'text',
        name: 'search',
        label: 'Name'
    }];
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Property[];
    public columns = [];

  constructor(private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef,
                private membershipService: MembershipService) { }

    public ngAfterViewInit(): void {
        this.columns = [
            {
                cellTemplate: this.actionsColumn, name: 'Select', flexGrow: 1.5, sortable: false
            },
            {prop: 'id', name: 'ID', flexGrow: 2, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'name', name: 'Name', flexGrow: 1, sortable: false},
            {cellTemplate: this.addressColumn, prop: 'address', name: 'Address', flexGrow: 2, sortable: false},
            {prop: 'city', name: 'City', flexGrow: 0.9, sortable: false},
            {prop: 'country', name: 'Country', flexGrow: 0.9, sortable: false}
        ];
    }

    public fetchPage(params?: OptionalHttpParams): void {
        params = _.omit(params, ['order_by', 'order_strategy']);
        this.propertyService.getPropertiesList(params).subscribe(
                (response: PropertiesResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.properties;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                }
        );
    }

    public getButtonStatus(row: Property): boolean {
        return !this.membershipService.massAddPropertiesList.includes(row.id);
}

    public selectProperty(data: Property): void {
        this.membershipService.massAddPropertiesList.push(data.id);
    }

    public removeDevice(data: Property): void {
        this.membershipService.massAddPropertiesList = _.filter(this.membershipService.massAddPropertiesList, (item) => item !== data.id);
    }

    public selectPage(): void {
        this.membershipService.massAddPropertiesList = [];
        for(const thing of this.rows){
            this.selectProperty(thing);
        }
    }

}
