import {AverageConsumption} from './../../_internal/average.consumption';
import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ThingResponse, ThingsResponse} from './dto/thing.response';
import {TotalUsage} from '../../_internal/total.usage';
import {ThingEventResponse, ThingEventsResponse} from './dto/thing-event.response';
import {ApplianceCategoryResponse} from './dto/appliance-category';
import { PressureTestsResponse, PressureTestResponse } from './dto/pressure-tests.response';
import { ThingUpdateData } from '../../_internal/thing-update.response';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import { HourlyStatistics } from './dto/hourly-statistics';
import { AggregatedDailyData } from './dto/aggregated-daily-data';
import { BurningTestResultResponse } from './dto/burning-mode';
import { AdvancedValveRaport } from './dto/advanced-valve-raport';
import {FirmwareSettings} from './dto/firmware-device-settings.response';

@Injectable({
    providedIn: 'root'
})
export class ThingResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/things`;
    private readonly provisionerUrl: string = `${environment.apiGatewayUrl}/new-device-provisioner/v1`;
    private readonly firmwareUrl: string = `${environment.apiGatewayUrl}/firmware/devices`;


    constructor(private http: HttpClient) {
    }

    public getThingsList(params = {}): Observable<ThingsResponse> {
        return this.http.get<ThingsResponse>(this.url, {params});
    }

    public getThing(thingId: string): Observable<ThingResponse> {
        return this.http.get<ThingResponse>(`${this.url}/${thingId}`);
    }

    public updateThing(thingId: string, payload: ThingUpdateData): Observable<ThingResponse> {
        return this.http.put<ThingResponse>(`${this.url}/${thingId}`, {thing: payload});
    }

    public updateValveState(thingId: string, payload: {state: string}): Observable<ThingResponse> {
        return this.http.put<ThingResponse>(`${this.url}/${thingId}`, {thing: payload});
    }

    public getEvents(thingId: string, event_start: string, event_end: string): Observable<ThingEventsResponse> {
        return this.http.get<ThingEventsResponse>(`${this.url}/${thingId}/events`, {params: {event_start, event_end}});
    }

    public getApplianceCategories(thingId: string): Observable<ApplianceCategoryResponse> {
        return this.http.get<ApplianceCategoryResponse>(`${this.url}/${thingId}/appliance_categories`);
    }

    public getFirmwareSettings(thingId: string, headers: {Accept: string}): Observable<FirmwareSettings> {
        return this.http.get<FirmwareSettings>(`${this.firmwareUrl}/${thingId}`, {headers});
    }

    public updateFirmwareSettings(thingId: string, payload: FirmwareSettings, headers: {'Content-Type': string; Accept: string}): Observable<FirmwareSettings> {
        return this.http.put<FirmwareSettings>(`${this.firmwareUrl}/${thingId}`, payload, {headers});
    }

    public updateAppliancesInEvent(thingId: string, eventStart: string, applianceIds: string[]): Observable<ThingEventResponse> {
        return this.http.put<ThingEventResponse>(
            `${this.url}/${thingId}/events/${eventStart}/update_appliances`,
            {appliance_ids: applianceIds});
    }

    public getTotalUsage(thingId: string, queryParams: string = ''): Observable<TotalUsage> {
        const url = `${this.url}/${thingId}/total_usage${queryParams}`;
        return this.http.get<TotalUsage>(url);
    }

    public getDailyUsage(thingId: string, utcStart: string, utcEnd: string): Observable<AggregatedDailyData> {
        const params = {
            from: utcStart,
            to: utcEnd
        };
        const url = `${this.url}/${thingId}/daily_usage`;
        return this.http.get<AggregatedDailyData>(url, {params});
    }

    public getPressureTests(thingId: string): Observable<PressureTestsResponse> {
        const url = `${this.url}/${thingId}/pressure_tests`;
        return this.http.get<PressureTestsResponse>(url);
    }

    public getPressureTestsPaginated(thingId: string, params?: HttpParams | OptionalHttpParams ): Observable<PressureTestsResponse> {
        const url = `${this.url}/${thingId}/pressure_tests`;
        return this.http.get<PressureTestsResponse>(url, {params});
    }

    public triggerPressureTests(thingId: string): Observable<PressureTestResponse> {
        const url = `${this.url}/${thingId}/trigger_pressure_test`;
        return this.http.post<PressureTestResponse>(url, {});
    }

    public getHourlyStatistics(thingId: string, params: {from: number; to: number}): Observable<HourlyStatistics[]> {
        const url = `${this.url}/${thingId}/aggregated_stats`;
        return this.http.get<any>(url, {params});
    }

    public getBurningReportsData(serialNo: string): Observable<BurningTestResultResponse> {
        const url = `${this.provisionerUrl}/devices/${serialNo}/burning_reports`;
        return this.http.get<BurningTestResultResponse>(url);
    }

    public getAdvancedValveRaportData(thingId: string, from: number, to: number): Observable<AdvancedValveRaport[]> {
        const params = {
            from,
            to
        };
        const url = `${this.url}/${thingId}/advanced_raport`;
        return this.http.get<AdvancedValveRaport[]>(url, {params});
    }
}
