<div class="row">
    <div class="col-lg-6">
        <div class="card mb-4"
             *ngIf="hub">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> {{ hub.name }}
                <div class="float-end buttons-row">
                    <app-favourites-button
                        location="hubs"
                        [rowID]="hub.id"
                        [rowName]="hub.name">
                    </app-favourites-button>
                    <span class="btn-toolbar text-center well float-end">

                        <button class="btn btn-secondary btn-sm"

                                (click)="openUpdateDialog()">
                            Update
                            <i class="fa fa-download"></i>
                        </button>
                        <button
                            class="btn btn-secondary btn-sm"
                            (click)="openEditModal()">
                                Edit
                                <i class="fa icons icon-pencil"></i>
                        </button>
                        <button class="btn btn-secondary btn-sm"

                                *appHasRoles="'root'"
                                (click)="deleteHub()">
                            Delete
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>ID:</dt>
                    <dd>
                        {{ hub.id }}
                    </dd>

                    <dt>Name:</dt>
                    <dd>
                        {{ hub.name }}
                    </dd>

                    <dt>Amazon Resource Name:</dt>
                    <dd>
                        {{ hub.arn }}
                    </dd>

                    <dt>Space ID:</dt>
                    <dd>
                        {{ hub.space_id }}
                    </dd>

                    <dt>Serial number:</dt>
                    <dd>
                        {{ hub.serial_no }}
                    </dd>

                    <dt>Type:</dt>
                    <dd>
                        {{ hub.type }}
                    </dd>

                    <dt>Status:</dt>
                    <dd>
                        {{ hub.status }}
                    </dd>

                    <dt>Version:</dt>
                    <dd>
                        {{ hub.version }}
                    </dd>

                    <dt>Modem Version:</dt>
                    <dd>
                        {{ hub.modem_version }}
                    </dd>

                    <dt>Firmware channel:</dt>
                    <dd>
                        {{ hub.firmware_channel }}
                    </dd>
                    <dt>Upgrade in progress</dt>
                    <dd>
                        {{ hub.upgrade_in_progress }}
                    </dd>
                    <dt>Cloud connection:</dt>
                    <dd>
                        <div [ngSwitch]="hub.online">
                            <c-badge color="success"
                                     *ngSwitchCase="true">Connected
                            </c-badge>
                            <c-badge color="danger"
                                     *ngSwitchCase="false">Disconnected
                            </c-badge>
                        </div>
                    </dd>
                    <dt>Group upgrade:</dt>
                    <dd>
                        <div [ngSwitch]="hub.mass_firmware_upgrade_disabled">
                            <c-badge color="success"
                                     *ngSwitchCase="false">Allowed
                            </c-badge>
                            <c-badge color="danger"
                                     *ngSwitchCase="true">Blocked
                            </c-badge>
                        </div>
                    </dd>
                </dl>
                <div class="btn-group">

                    <app-trigger-reset-button *ngIf="hub"
                                              [hubId]="hubId"></app-trigger-reset-button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <app-thing-list [tabTitle]="'Associated things'"
                        [hubs]="[hub]"
                        *ngIf="hub"
                        [hub]="hub">
        </app-thing-list>
    </div>
</div>

<tabset>
    <tab id="firmwares" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Firmwares
        </ng-template>
        <div class="row"
             *ngIf="activeTab === 'firmwares'">
            <div class="col-12">
                <app-available-firmware [hub]="hub"
                                        [listType]="'Requested Firmwares'"></app-available-firmware>
            </div>
        </div>
    </tab>
    <tab id="logs" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Logs
        </ng-template>
        <div class="row" *ngIf="activeTab === 'logs'">
            <div class="col-12">
                <app-logs-list [resourceId]="hubId"></app-logs-list>
            </div>
        </div>
    </tab>
    <tab id="events" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Lifecycle events
        </ng-template>
        <div class="row" *ngIf="activeTab === 'events'">
            <div class="col-12">
                <app-connectivity-timeline title="Hub"
                                           [hubId]="hubId"></app-connectivity-timeline>
            </div>
        </div>
    </tab>
    <tab id="resets" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Remote resets
        </ng-template>
        <div class="row" *ngIf="activeTab === 'resets'">
            <div class="col-12">
                <app-trigger-resets-list [hubId]="hubId"></app-trigger-resets-list>
            </div>
        </div>
    </tab>
    <tab id="shadow_logs" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Shadow logs
        </ng-template>
        <div class="row" *ngIf="activeTab === 'shadow_logs'">
            <div class="col-12">
                <app-shadow-logs [hubId]="hubId"></app-shadow-logs>
            </div>
        </div>
    </tab>
    <tab id="swaps" (selectTab)="tabSelect($event)">
        <ng-template tabHeading>
            Swaps
        </ng-template>
        <div class="row" *ngIf="activeTab === 'swaps'">
            <div class="col-12">
                <app-swap-data [hubId]="hubId"></app-swap-data>
            </div>
        </div>
    </tab>
</tabset>
